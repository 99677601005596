import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Typography,
} from 'antd';
import messages from './messages';
import { PatternFormat } from 'react-number-format';
import { useIntl } from 'react-intl';

const firstColor = `hsl(${Math.random() * 360}, 75%, 20%)`;
const secondColor = `hsl(${Math.random() * 360}, 75%, 20%)`;
const backgroundImage = {
  backgroundImage: `linear-gradient(25deg, ${firstColor} , ${secondColor})`,
};

const LoginForm = ({ onSubmit, onSubmitFailed, search }) => {
  const intl = useIntl();
  return (
    <Layout style={{ height: 'calc(100vh - 128px)' }}>
      <Row
        gutter={{ xs: 32, sm: 32, md: 16, lg: 8 }}
        justify="center"
        align="middle"
        style={{
          width: '100%',
          height: '100%',
          padding: 16,
          ...backgroundImage,
          overflowY: 'auto',
          marginRight: 0,
          marginLeft: 0,
        }}
      >
        <Col
          xs={24}
          sm={16}
          md={10}
          xl={8}
          xxl={6}
          justify="center"
          align="middle"
          flex={1}
        >
          <Typography.Paragraph style={{ color: 'white' }}>
            {intl.formatMessage(messages.loginInfo)}
          </Typography.Paragraph>
          <Divider />
          <Form
            name="basic"
            labelCol={{ span: 8, color: 'white' }}
            wrapperCol={{ span: 24 }}
            initialValues={{
              caseNumber: search.get('caseNumber') || '',
              pinNumber: search.get('pinNumber') || '',
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmitFailed}
            autoComplete="off"
          >
            <Form.Item
              label={intl.formatMessage(messages.loginCaseNumber)}
              className="login-item"
              name="caseNumber"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(messages.loginRequiredError),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage(messages.loginPinNumber)}
              className="login-item"
              name="pinNumber"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(messages.loginRequiredError),
                },
                {
                  pattern: '^\\d{4}[ ]\\d{4}[ ]\\d{4}$',
                  message: intl.formatMessage(messages.loginPinError),
                },
              ]}
            >
              <PatternFormat
                format="#### #### ####"
                mask="_"
                customInput={Input}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ xxl: 20, xl: 20, md: 20, sm: 20, xs: 24 }}>
              <Button type="primary" htmlType="submit">
                {intl.formatMessage(messages.loginSubmitButton)}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitFailed: PropTypes.func.isRequired,
  search: PropTypes.object,
};

export default LoginForm;
