import React from 'react';
import { Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import messages from './messages';

const PaypalFields = () => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        label={intl.formatMessage(messages.refundFormPaypalField)}
        name="paypal"
        type="email"
        autoComplete="email"
        rules={[
          {
            type: 'email',
            message: intl.formatMessage(messages.refundFormInvalidEmailAddress),
          },
          {
            required: true,
            message: intl.formatMessage(messages.refundFormNoEmailAddress),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage(messages.refundFormPaypalConfirmField)}
        name="paypalConfirm"
        autoComplete="email"
        type="email"
        dependencies={['paypal']}
        rules={[
          {
            type: 'email',
            message: intl.formatMessage(messages.refundFormInvalidEmailAddress),
          },
          {
            required: true,
            message: intl.formatMessage(
              messages.refundFormNoConfirmationEmailAddress,
            ),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('paypal') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  intl.formatMessage(messages.refundFormEmailAddressDifferent),
                ),
              );
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
};

PaypalFields.propTypes = {};

export default PaypalFields;
