import { Upload, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import messages from './messages';
import { useIntl } from 'react-intl';
import PdfDocument from './PdfDocument';

const MAX_FILE_SIZE = 50;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const mockData = {
  previewVisible: false,
  isPdf: false,
  pdfFile: null,
  previewImage: '',
  previewTitle: '',
  fileList: [],
};

const UploadSection = ({
  pinNumber,
  caseNumber,
  updateAttachments,
  setIsUploading,
}) => {
  const intl = useIntl();
  const [state, setState] = useState(mockData);

  const handleCancel = useCallback(
    () => setState((old) => ({ ...old, previewVisible: false })),
    [],
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    const isPdf = file.type.indexOf('pdf') > -1;

    setState((old) => ({
      ...old,
      previewImage: file.url || file.preview,
      isPdf,
      pdfFile: isPdf ? file : null,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    }));
  };

  const beforeUpload = (file) => {
    if (file.size / 1024 / 1024 > MAX_FILE_SIZE) {
      message.error(intl.formatMessage(messages.refundFormFileSizeError));
      return Upload.LIST_IGNORE;
    }
    if (file.type.indexOf('image') === -1 && file.type.indexOf('pdf') === -1) {
      message.error(intl.formatMessage(messages.refundFormFileTypeError));
      return Upload.LIST_IGNORE;
    }
    setIsUploading(true);
  };

  const handleChange = ({ fileList, file }) => {
    console.log(file.status);
    if (file.status === 'done') {
      fileList.forEach((existingFile) => {
        if (existingFile.uid === file.uid) {
          existingFile.thumbUrl = file.response?.thumbUrl;
          existingFile.url = file.response?.url;
          existingFile.id = file.response?.id;
        }
      });
    }
    if (file.status === 'error') {
      message.error(intl.formatMessage(messages.refundFormUploadError));
    }
    setState((old) => ({ ...old, fileList }));
    updateAttachments([...fileList.map((f) => f?.id)]);
    if (file.status === 'done' || file.status === 'error') {
      setIsUploading(false);
    }
  };

  const {
    previewVisible,
    previewImage,
    fileList,
    previewTitle,
    isPdf,
    pdfFile,
  } = state;

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        {intl.formatMessage(messages.refundFormUploadButton)}
      </div>
    </div>
  );
  return (
    <>
      <Upload
        action="/api/services/upload"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        data={{ pinNumber, caseNumber }}
        accept="image/*,.pdf"
      >
        {fileList.length > 3 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        width={isPdf ? 880 : 520}
        destroyOnClose
      >
        {isPdf ? (
          <PdfDocument pdfFile={pdfFile} key={pdfFile} />
        ) : (
          <img
            alt={previewTitle}
            style={{ width: '100%' }}
            src={previewImage}
          />
        )}
      </Modal>
    </>
  );
};

UploadSection.propTypes = {
  pinNumber: PropTypes.string,
  caseNumber: PropTypes.string,
  updateAttachments: PropTypes.func,
  setIsUploading: PropTypes.func,
};

export default UploadSection;
