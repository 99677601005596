import { defineMessages } from "react-intl";

export default defineMessages({
  privacyPolicy: {
    id: `privacyPolicy`,
    defaultMessage: "Privacy Policy"
  },
  imprint: {
    id: `imprint`,
    defaultMessage: "Imprint"
  }
});
