import { defineMessages } from "react-intl";

export default defineMessages({
  loginCaseNumber: {
    id: `loginCaseNumber`,
    defaultMessage: "Case number"
  },
  loginPinNumber: {
    id: `loginPinNumber`,
    defaultMessage: "PIN number"
  },
  loginSubmitButton: {
    id: `loginSubmitButton`,
    defaultMessage: "Submit"
  },
  loginRequiredError: {
    id: `loginRequiredError`,
    defaultMessage: "This field is required"
  },
  loginPinError: {
    id: `loginPinError`,
    defaultMessage: "PIN must have 12 characters"
  },
  loginInfo: {
    id: `loginInfo`,
    defaultMessage: "In order to be able to provide the information about your refund preferences, please fill the required fields"
  }
});
