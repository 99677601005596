import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";
import useLogin from "../../api/useLogin";
import LoginForm from "../../components/LoginForm";

const Login = () => {
  const [search] = useSearchParams();
  const { login } = useLogin();

  const onSubmit = useCallback((values) => {
    login(values);
  }, [login]);

  const onSubmitFailed = useCallback((err) => {
    err.errorFields.forEach(field =>
      field.errors.map((e) =>
        message.error(`${field.name[0]?.toUpperCase()} : ${e}`)
      )
    );
  }, []);

  return (
    <LoginForm
      onSubmit={onSubmit}
      onSubmitFailed={onSubmitFailed}
      search={search} />
  );
};

export default Login;
