import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'antd';
import BankFields from './BankFields';
import PaypalFields from './PaypalFields';
import { useIntl } from 'react-intl';
import messages from './messages';
import UploadSection from './UploadSection';

const PaymentDetails = ({
  currency,
  onFinish,
  onFinishFailed,
  caseNumber,
  pinNumber,
  loading,
  updateAttachments,
  refundBankOwner,
  transferType,
}) => {
  const intl = useIntl();
  const [isUploading, setIsUploading] = useState(false);

  const fieldsType = useMemo(() => {
    if (transferType) {
      return transferType === 'WIRED' ? 'BANK' : 'PAYPAL';
    }
    return currency === 'EUR' ? 'BANK' : 'PAYPAL';
  }, [transferType, currency]);

  return (
    <>
      <Col
        align="middle"
        xxl={8}
        lg={10}
        xl={10}
        md={24}
        sm={24}
        xs={24}
        flex={1}
      >
        <UploadSection
          pinNumber={pinNumber}
          caseNumber={caseNumber}
          updateAttachments={updateAttachments}
          setIsUploading={setIsUploading}
        />
        <Form
          labelCol={{ xxl: 8, lg: 10, xl: 9, md: 6, sm: 8, xs: 8 }}
          initialValues={{
            iban: '',
            bic: '',
            paypal: '',
            paypalConfirm: '',
            caseNumber,
            refundBankOwner,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {fieldsType === 'BANK' ? (
            <BankFields currency={currency} refundBankOwner={refundBankOwner} />
          ) : (
            <PaypalFields />
          )}
          <Row align="center" justify="center">
            <Col flex={1}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading || isUploading}
              >
                {intl.formatMessage(messages.refundFormSubmit)}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </>
  );
};

PaymentDetails.propTypes = {
  currency: PropTypes.string,
  onFinish: PropTypes.func,
  onFinishFailed: PropTypes.func,
  caseNumber: PropTypes.string,
  pinNumber: PropTypes.string,
  updateAttachments: PropTypes.func,
  refundBankOwner: PropTypes.string,
  transferType: PropTypes.string,
};

export default PaymentDetails;
