import { useState } from "react";
import { message } from "antd";
import axios from "axios";

const fetchData = async (url, payload) => {
  try {
    if (payload) {
      return await axios.post(url, payload);
    } else {
      return await axios.get(url);
    }
  } catch (e) {
    throw e;
  }
};

const useFetch = (baseUrl) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [url] = useState(baseUrl);

  const getData = () => {
    setLoading(true);
    fetchData(url)
      .then((resp) => setData(resp.data))
      .catch((error) => {
        const { Errors } = JSON.parse(error.request.response);
        if (Errors) {
          Errors.map((e) => message.error(e));
        } else {
          message.error(error.message);
        }
        setData(null);
      })
      .finally(() => setLoading(false));
  };

  const postData = (payload, callback) => {
    setLoading(true);
    fetchData(url, payload)
      .then((resp) => {
        setData(resp);
        callback.call(null, resp);
      })
      .catch((error) => {
        const { Errors } = JSON.parse(error.request.response);
        if (Errors) {
          Errors.map((e) => message.error(e));
        } else {
          message.error(error.message);
        }
        setData(null);
      })
      .finally(() => setLoading(false));
  };


  return { data, loading, getData, postData };
};

export default useFetch;
