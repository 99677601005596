import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Divider, Layout, message, Result, Row } from 'antd';
import ItemsTable from './ItemsTable';
import PaymentDetails from './PaymentDetails';
import DetailsSection from './DetailsSection';
import { useIntl } from 'react-intl';
import messages from './messages';
import useFetch from '../../api/useFetch';

const RefundForm = ({
  caseNumber,
  pinNumber,
  setDataProcessed,
  dataProcessed,
}) => {
  const intl = useIntl();
  const [attachments, setAttachments] = useState([]);

  const caseApi = useFetch(
    `/api/services/cases/enquiry/input?caseNumber=${caseNumber}&pinNumber=${pinNumber}`,
  );
  const formApi = useFetch(`/api/services/cases/enquiry/add-banking-info`);

  useEffect(() => {
    if (caseNumber && !dataProcessed) {
      caseApi.getData();
    }
  }, [caseNumber, dataProcessed]);

  const onFinish = useCallback(
    (data) => {
      if (caseApi.data?.receiptMandatory && attachments.length === 0) {
        message.error(intl.formatMessage(messages.refundFormNoAttachments));
        return;
      }
      formApi.postData({ ...data, caseNumber, pinNumber, attachments }, () =>
        setDataProcessed(true),
      );
    },
    [formApi, caseNumber, pinNumber, attachments],
  );

  const onFinishFailed = useCallback(() => {
    message.error(intl.formatMessage(messages.refundFormInvalidForm));
  }, [intl]);

  const currency = useMemo(
    () => caseApi.data?.items[0].currency,
    [caseApi.data],
  );
  const refundBankOwner = useMemo(
    () => caseApi.data?.address?.name,
    [caseApi.data],
  );

  return (
    <Layout style={{ height: 'calc(100vh - 128px)' }}>
      <Row
        gutter={{ xs: 24, sm: 16, md: 16, lg: 8, xxl: 8 }}
        style={{
          padding: 15,
          marginLeft: 0,
          marginRight: 0,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <DetailsSection data={caseApi.data} />
        <Col span={24}>
          <Divider orientation="middle">
            {intl.formatMessage(messages.refundFormItems)}
          </Divider>
          <ItemsTable data={caseApi.data?.items} loading={caseApi.loading} />
        </Col>
        <Col className="gutter-row" span={24} justify="center">
          <Divider orientation="middle">
            {intl.formatMessage(messages.refundFormPayment)}
          </Divider>
          {dataProcessed ? (
            <Result
              style={{ paddingTop: 20 }}
              status="success"
              title={intl.formatMessage(messages.refundFormDataSuccessTitle)}
              subTitle={intl.formatMessage(
                messages.refundFormDataSuccessMessage,
              )}
            />
          ) : (
            <Row justify="center">
              <PaymentDetails
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                caseNumber={caseNumber}
                pinNumber={pinNumber}
                currency={currency}
                loading={formApi.loading}
                updateAttachments={setAttachments}
                refundBankOwner={refundBankOwner}
                transferType={caseApi.data?.transferType}
              />
            </Row>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default RefundForm;
