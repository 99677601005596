import { defineMessages } from "react-intl";

export default defineMessages({
  refundGoToLogin: {
    id: `refundGoToLogin`,
    defaultMessage: "Sorry, you are not authorized to access this page."
  },
  refundBack: {
    id: `refundBack`,
    defaultMessage: "Back to login"
  }
});
