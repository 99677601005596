import React, { useEffect } from "react";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Route, Routes } from "react-router-dom";
import Login from "../Login";
import Header from "../../components/Header";
import NotFound from "../../components/NotFound";
import Refund from "../Refund";
import PrivacyPolicy from "../PrivacyPolicy";
import Footer from "../../components/Footer";
import { useIntl } from "react-intl";
import messages from "../../components/Header/messages";

function App() {
  const intl = useIntl();
  useEffect(() => {
    document.title = intl.formatMessage(messages.globalHeader);
  }, [intl]);

  return (
    <Layout>
      <Header />
      <Content style={{ paddingTop: 64 }} role="main">
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/refund" exact element={<Refund />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/imprint" exact element={<PrivacyPolicy imprint />} />
          <Route path="*" exact element={<NotFound />} />
        </Routes>
      </Content>
      <Footer />
    </Layout>
  );
}

export default App;
