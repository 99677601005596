import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import messages from './messages';
import { isValidBIC, isValidIBAN } from 'ibantools';
import { useIntl } from 'react-intl';

const BankFields = ({ refundBankOwner, currency }) => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        label={intl.formatMessage(messages.refundFormIban)}
        name="iban"
        normalize={(value) => String(value).toUpperCase()}
        rules={[
          {
            required: true,
            message: intl.formatMessage(messages.refundFormIbanRequired),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const iban = getFieldValue('iban');
              if (!iban || isValidIBAN(iban)) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(intl.formatMessage(messages.refundFormInvalidIban)),
              );
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage(messages.refundFormBic)}
        name="bic"
        normalize={(value) => String(value).toUpperCase()}
        rules={[
          {
            required: currency !== 'EUR',
            message: intl.formatMessage(messages.refundFormBicRequired),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const bic = getFieldValue('bic');
              if (!bic || isValidBIC(bic)) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(intl.formatMessage(messages.refundFormInvalidBic)),
              );
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage(messages.refundFormRefundBankOwner)}
        name="refundBankOwner"
        initialValue={refundBankOwner}
        rules={[
          {
            required: true,
            message: intl.formatMessage(messages.refundFormRequiredError),
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
};

BankFields.propTypes = {
  refundBankOwner: PropTypes.string,
  currency: PropTypes.string,
};

export default BankFields;
