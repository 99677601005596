import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Table, Typography } from "antd";
import { useIntl } from "react-intl";
import messages from "./messages";

const getColumns = (intl) => [
  {
    title: intl.formatMessage(messages.name),
    dataIndex: "itemName"
  },
  {
    title: intl.formatMessage(messages.refundFormProductCode),
    dataIndex: "productCode"
  },
  {
    title: intl.formatMessage(messages.refundFormPrice),
    align: "right",
    dataIndex: "refundValue",
    render: (text, { currency }) => `${intl.formatNumber(parseFloat(text).toFixed(2).toString(), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })} ${currency}`
  }
];

const ItemsTable = ({ data = [], loading }) => {
  const intl = useIntl();

  const columns = useMemo(() => getColumns(intl), [intl]);

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      loading={loading}
      bordered
      size="small"
      rowKey="itemName"
      summary={pageData => {

        const totalPrice = pageData.reduce((acc, { refundValue }) => {
          return acc + refundValue;
        }, 0);

        const currency = pageData[0]?.currency;

        return (
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={2} align="right">
              <Typography.Text strong>{intl.formatMessage(messages.refundFormTotalPrice)}
              </Typography.Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell align="right">
              <Typography.Text type="success"
                               strong>{`${intl.formatNumber(totalPrice.toFixed(2).toString(), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })} ${currency || ""}`}</Typography.Text>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
};

ItemsTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool
};

export default ItemsTable;
