import React from 'react';
import PropTypes from 'prop-types';
import { Col, Divider, Space, Typography } from 'antd';
import { useIntl } from 'react-intl';
import messages from './messages';

const DetailsSection = ({ data }) => {
  const intl = useIntl();
  return (
    <>
      <Col>
        <Divider orientation="left">
          {intl.formatMessage(messages.refundFormCase)}
        </Divider>
        <Space style={{ padding: 8 }}>
          <Typography.Text>
            {intl.formatMessage(messages.refundFormCaseNumber, {
              number: data?.caseNumber,
            })}
          </Typography.Text>
        </Space>
      </Col>
      <Col>
        <Divider orientation="left">
          {intl.formatMessage(messages.refundFormCustomer)}
        </Divider>
        <Space direction="vertical" style={{ padding: 8 }}>
          <Typography.Text>
            {intl.formatMessage(messages.refundFormCustomerNumber, {
              number: data?.customerNumber,
            })}
          </Typography.Text>
          <Typography.Text>
            {intl.formatMessage(messages.refundFormCustomerEmail, {
              email: data?.email,
            })}
          </Typography.Text>
        </Space>
      </Col>
      <Col>
        <Divider orientation="left">
          {intl.formatMessage(messages.refundFormDelivery)}
        </Divider>
        <Space direction="vertical" style={{ padding: 4 }}>
          {data?.address &&
            Object.entries(data?.address).map(([key, value]) => {
              if (!value) {
                return null;
              }
              return (
                <Typography.Text key={key}>
                  {intl.formatMessage(messages[key])}: {value}
                </Typography.Text>
              );
            })}
        </Space>
      </Col>
    </>
  );
};

DetailsSection.propTypes = {
  data: PropTypes.object,
};

export default DetailsSection;
