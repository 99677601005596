import React from "react";
import PrivacyPolicyView from "../../components/PrivacyPolicyView";

const PrivacyPolicy = ({ imprint }) => {
  return (
    <PrivacyPolicyView imprint={imprint} />
  );
};

export default PrivacyPolicy;
