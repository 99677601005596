import { defineMessages } from 'react-intl';

export default defineMessages({
  refundFormGoToLogin: {
    id: `refundFormGoToLogin`,
    defaultMessage: 'Sorry, you are not authorized to access this page.',
  },
  refundFormBack: {
    id: `refundFormBack`,
    defaultMessage: 'Back to login',
  },
  refundFormInvalidEmailAddress: {
    id: `refundFormInvalidEmailAddress`,
    defaultMessage: 'Email not valid',
  },
  refundFormNoConfirmationEmailAddress: {
    id: `refundFormNoConfirmationEmailAddress`,
    defaultMessage: 'Please confirm your email address!',
  },
  refundFormNoEmailAddress: {
    id: `refundFormNoEmailAddress`,
    defaultMessage: 'Please input your email address!',
  },
  refundFormEmailAddressDifferent: {
    id: `refundFormEmailAddressDifferent`,
    defaultMessage: 'The address that you entered does not match!',
  },
  refundFormPaypalField: {
    id: `refundFormPaypalField`,
    defaultMessage: 'PayPal address',
  },
  refundFormPaypalConfirmField: {
    id: `refundFormPaypalConfirmField`,
    defaultMessage: 'Confirm PayPal address',
  },
  refundFormInvalidIban: {
    id: `refundFormInvalidIban`,
    defaultMessage: 'IBAN number is not valid',
  },
  refundFormInvalidBic: {
    id: `refundFormInvalidBic`,
    defaultMessage: 'BIC number is not valid',
  },
  refundFormIban: {
    id: `refundFormIban`,
    defaultMessage: 'IBAN',
  },
  refundFormBic: {
    id: `refundFormBic`,
    defaultMessage: 'BIC',
  },
  refundFormIbanRequired: {
    id: `refundFormIbanRequired`,
    defaultMessage: 'IBAN field is required',
  },
  refundFormBicRequired: {
    id: `refundFormBicRequired`,
    defaultMessage: 'BIC field is required',
  },
  refundFormDataSuccessTitle: {
    id: `refundFormDataSuccessTitle`,
    defaultMessage: 'Your data was successfully sent!',
  },
  refundFormDataSuccessMessage: {
    id: `refundFormDataSuccessMessage`,
    defaultMessage: 'You will receive your payment in the next 24 to 48 hours',
  },
  refundFormSubmit: {
    id: `refundFormSubmit`,
    defaultMessage: 'Submit',
  },
  refundFormInvalidForm: {
    id: `refundFormInvalidForm`,
    defaultMessage: 'Form is invalid',
  },
  refundFormItems: {
    id: `refundFormItems`,
    defaultMessage: 'Items',
  },
  refundFormPayment: {
    id: `refundFormPayment`,
    defaultMessage: 'Payment',
  },
  refundFormCase: {
    id: `refundFormCase`,
    defaultMessage: 'Case',
  },
  refundFormCaseNumber: {
    id: `refundFormCaseNumber`,
    defaultMessage: 'Number: {number}',
  },
  refundFormCustomer: {
    id: `refundFormCustomer`,
    defaultMessage: 'Customer',
  },
  refundFormCustomerNumber: {
    id: `refundFormCustomerNumber`,
    defaultMessage: 'Number: {number}',
  },
  refundFormCustomerEmail: {
    id: `refundFormCustomerEmail`,
    defaultMessage: 'Email: {email}',
  },
  refundFormDelivery: {
    id: `refundFormDelivery`,
    defaultMessage: 'Delivery',
  },
  name: {
    id: `name`,
    defaultMessage: 'Name',
  },
  refundFormProductCode: {
    id: `refundFormProductCode`,
    defaultMessage: 'Product code',
  },
  refundFormPrice: {
    id: `refundFormPrice`,
    defaultMessage: 'Price',
  },
  refundFormTotalPrice: {
    id: `refundFormTotalPrice`,
    defaultMessage: 'Total refund',
  },
  extra1: {
    id: `extra1`,
    defaultMessage: 'Extra 1',
  },
  extra2: {
    id: `extra2`,
    defaultMessage: 'Extra 2',
  },
  street: {
    id: `street`,
    defaultMessage: 'Street',
  },
  city: {
    id: `city`,
    defaultMessage: 'City',
  },
  country: {
    id: `country`,
    defaultMessage: 'Country',
  },
  postcode: {
    id: `postcode`,
    defaultMessage: 'Postcode',
  },
  refundFormUploadButton: {
    id: `refundFormUploadButton`,
    defaultMessage: 'Upload receipt',
  },
  refundFormNoAttachments: {
    id: `refundFormNoAttachments`,
    defaultMessage: 'You must upload at least one receipt',
  },
  refundFormFileSizeError: {
    id: `refundFormFileSizeError`,
    defaultMessage: 'File size exceeded, maximum size is 50MB',
  },
  refundFormUploadError: {
    id: `refundFormUploadError`,
    defaultMessage: 'Upload has failed, please try again!',
  },
  refundFormFileTypeError: {
    id: `refundFormFileTypeError`,
    defaultMessage: 'File type not supported, please try again!',
  },
  refundFormCurrentPage: {
    id: `refundFormCurrentPage`,
    defaultMessage: 'Page {pageNumber} of {numPages}',
  },
  refundFormRequiredError: {
    id: `refundFormRequiredError`,
    defaultMessage: 'Field is required',
  },
  refundFormRefundBankOwner: {
    id: `refundFormRefundBankOwner`,
    defaultMessage: 'Account owner',
  },
});
