import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import messages from './messages';

pdfjs.GlobalWorkerOptions.workerSrc = './pdf.worker.min.js';

export default function PdfDocument({ pdfFile }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const intl = useIntl();

  const onDocumentLoadSuccess = (options) => setNumPages(options.numPages);

  return (
    <>
      <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignContent: 'center' }}>
          <small>
            {intl.formatMessage(messages.refundFormCurrentPage, {
              pageNumber,
              numPages,
            })}
          </small>
        </div>
        <div
          style={{
            width: 100,
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <CaretLeftOutlined
            style={{ cursor: pageNumber <= 1 ? 'default' : 'pointer' }}
            disabled={pageNumber <= 1}
            onClick={() => {
              if (pageNumber > 1) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />

          <CaretRightOutlined
            style={{ cursor: pageNumber >= numPages ? 'default' : 'pointer' }}
            disabled={pageNumber >= numPages}
            onClick={() => {
              if (pageNumber < numPages) {
                setPageNumber(pageNumber + 1);
              }
            }}
          />
        </div>
      </div>
    </>
  );
}

PdfDocument.propTypes = {
  pdfFile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
