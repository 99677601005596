import React, { createContext, useState } from "react";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [dataProcessed, setDataProcessed] = useState(false);

  return (
    <AuthContext.Provider value={{ loggedIn, setLoggedIn, dataProcessed, setDataProcessed }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
